import {ChangeDetectorRef, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, Self, SimpleChanges} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {LabelDirective} from '@progress/kendo-angular-label';
import {CommonModule} from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'nit-decision-row',
  templateUrl: './decision-row.component.html',
  styleUrl: './decision-row.component.scss',
  standalone: true,
  imports: [LabelDirective, CommonModule],
})
export class DecisionRowComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() label: string = '[label]';
  @Input() type: 'checkbox' | 'radio' = 'checkbox';
  @Input() formControlName: string = '';
  @Input() styling: '' | 'danger' = '';
  @Input() isDisabled: boolean = false;
  @Input() value: boolean | string = false;
  @Input() autoHide: boolean = true;
  @Input() viewMode: boolean = false;
  @Output() changeOfChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

  touched: boolean = false;
  model: boolean | string;
  id: string;
  isChecked: boolean = false;

  private _value: any;
  private readonly _cdRef = inject(ChangeDetectorRef);

  constructor(@Self() private readonly ngControl: NgControl) {
    ngControl.valueAccessor = this;
    this.id = 'checkbox_' + Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }

  onChange = (val: any) => {
    this._value = val;
  };
  onTouched = () => { };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.type === 'checkbox' && changes.isDisabled?.currentValue) {
      this.value = false;
    }
    if (changes.value) {
      this._handleIsCheckedChange();
    }
  }

  ngOnInit() {
    if (this.type === 'radio') {
      this.ngControl.control.valueChanges.subscribe(value => {
        this._handleIsCheckedChange();
        if (this.model === value) return;
        this.writeValue(value);
      });
    }
  }

  change() {
    if (this.type === 'checkbox') {
      this.value = !this.value;
      this.onChange(this.value);
    }
    if (this.type === 'radio') {
      this.model = this.isChecked ? null : this.value;
      this.onChange(this.model);
    }
  }

  writeValue(val: boolean) {
    if (this.type === 'checkbox') this.value = val;
    if (this.type === 'radio') this.model = val;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
    if (disabled) this.value = false;
  }

  private _handleIsCheckedChange(): void {
    this.isChecked = this.type === 'checkbox' ?
      !!this.value : this.model === this.value;
    this.changeOfChecked.emit(this.isChecked);
  }
}
