<label class="decision-row" [class.danger]="styling" [class.checked]="isChecked" [hidden]="viewMode && !isChecked">
  <div>
    <label [class.disabled]="isDisabled || viewMode" [for]="id">{{label}}</label>
  </div>
  <div>
    <div class="checkbox-horizontal" [class.disabled]="isDisabled" [style.opacity]="viewMode ? 0 : 1">
      <input
        type="checkbox" [id]="id"
        [name]="formControlName"
        [checked]="isChecked"
        [disabled]="isDisabled || viewMode"
        (change)="change()">
      <span></span>
    </div>
  </div>
</label>
<ng-content *ngIf="!autoHide || isChecked" />
