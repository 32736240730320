import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DecisionService, NushDecisionService, TransferService} from '@nit-services';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Transfer} from '@nit-core/models/transfer';
import {LeaveType} from '@nit-core/global/domain/enums';
import {NValidate} from '@nit-core/forms';
import {NushDecision} from '@nit-core/models/nush-decision';
import {assembleStringDate} from '@nit-core/methods';
import {forkJoin, map} from 'rxjs';
import {Decision} from '@nit-models';
import {CommonModule} from '@angular/common';
import {DecisionRowComponent} from './decision-row/decision-row.component';
import {DecisionTextareaComponent} from './decision-textarea/decision-textarea.component';
import {LeaveFormComponent} from './leave-form/leave-form.component';
import {ButtonComponent} from '@nit-core/components/buttons/button/button.component';
import {DecisionDisabledStatePipe} from '@nit-core/pipes/decision-disabled-state.pipe';
import {NitToastr} from '@nit-core/services/global/nit-toastr.service';

@UntilDestroy()
@Component({
  selector: 'nit-decision-form',
  templateUrl: './decision-form.component.html',
  styleUrl: './decision-form.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DecisionRowComponent, DecisionTextareaComponent, LeaveFormComponent, ButtonComponent, DecisionDisabledStatePipe]
})
export class DecisionFormComponent implements OnChanges {
  @Input() decision: Decision | NushDecision;
  @Input() isTotalRatingPage: boolean = false;
  @Output() submitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() userHasGone: EventEmitter<string> = new EventEmitter<string>();
  leaveFormControl: FormControl;
  decisionForm: FormGroup;
  isDroppedSchoolChecked: boolean = false;

  constructor(private readonly _cdRef: ChangeDetectorRef,
              private readonly _nushDecisionService: NushDecisionService,
              private readonly _decisionService: DecisionService,
              private readonly _toastr: NitToastr,
              private readonly _transfersService: TransferService) {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.decision && this.decision) {
      const decisionValues = {...this.decision, decision: this._getDecisionType()};
      let leaveValues;
      if (this.decision?.leaveDetails || this.decision?.leaveDate) {
        leaveValues = {};
      }

      if (this.decision?.leaveDetails) leaveValues = this.decision?.leaveDetails;
      if (this.decision?.leaveDate) leaveValues.leaveDate = this.decision?.leaveDate;

      this.decisionForm.patchValue(decisionValues);
      this.leaveFormControl.patchValue(leaveValues, {emitEvent: false});
    }
  }

  onSubmit(): void {
    if (this.decisionForm.valid) {
      const decisionValue = this.decisionForm.value;
      const updates = {
        description: decisionValue.description,
        nextClass: decisionValue.decision === 'nextClass',
        certificateHonors: decisionValue.certificateHonors,
        commendationLetter: decisionValue.commendationLetter
      };

      const service = this.isTotalRatingPage ? this._decisionService : this._nushDecisionService;
      const requests = [service.update(this.decision.id, updates)];

      if (!this.decision.leaveTransferId && !updates?.nextClass) {
        const value = this.leaveFormControl.value;
        const date: string = assembleStringDate(value.leaveDate);
        let leaveType: LeaveType;
        const transfer: Transfer = {
          classId: this.decision.classId,
          userId: this.decision.userId,
          isArrived: false,
          date,
          leaveDetails: {
            orderNumber: value.orderNumber,
            isLeaveAddressUnknown: true
          },
        };

        if (decisionValue.decision !== 'finishedSchool') {
          if (!value.isLeaveAddressUnknown) {
            transfer.leaveDetails.isLeaveAddressUnknown = value.isLeaveAddressUnknown;
            transfer.leaveDetails.leaveAddress = value.leaveAddress;
          } else {
            transfer.leaveDetails.arbitraryLeaveInfo = value.arbitraryLeaveInfo;
          }
        }

        if (decisionValue.decision === 'repeatCourse') {
          delete transfer.leaveDetails.leaveAddress;
          transfer.leaveDetails.isLeaveAddressUnknown = true;
          transfer.leaveDetails.arbitraryLeaveInfo = 'Залишення для повторного здобуття загальної середньої освіти';
        }

        switch (decisionValue.decision) {
        case 'finishedSchool':
          leaveType = LeaveType.Graduated;
          break;
        case 'repeatCourse':
          leaveType = LeaveType.SecondYear;
          break;
        case 'droppedSchool':
          leaveType = LeaveType.Dropped;
          break;
        }
        transfer.leaveType = leaveType;

        requests.push(this._transfersService.create(transfer).pipe(map(() => ({isTotalRatingPage: this.isTotalRatingPage}))));
      }

      forkJoin(requests).subscribe((results ) => {
        results.forEach((isTotalRatingPage) => {
          if (isTotalRatingPage) {
            this.userHasGone.emit(this.decision.id);
          }
        });
        this._toastr.success();
        this.submitted.emit();
      });
    }
  }

  handleChangeOfChecked(isChecked: boolean): void {
    this.isDroppedSchoolChecked = isChecked;
    this._cdRef.detectChanges();
  }

  private _initForm(): void {
    this.leaveFormControl = new FormControl(null, NValidate.required);
    this.decisionForm = new FormGroup({
      decision: new FormControl(null, Validators.required),
      description: new FormControl(null),
      commendationLetter: new FormControl(false),
      certificateHonors: new FormControl(false),
    });

    this.decisionForm.get('decision')?.valueChanges.subscribe(val => {
      if (val === 'repeatCourse') {
        this.decisionForm.get('commendationLetter').setValue(false, {emitEvent: false});
        this.decisionForm.get('certificateHonors').setValue(false, {emitEvent: false});
      }
    });

    this.decisionForm.valueChanges.subscribe(() => this._updateValidators());
  }

  private _getDecisionType(): string {
    if (this.decision?.nextClass) return 'nextClass';
    if (this.decision?.finishedSchool) return 'finishedSchool';
    if (this.decision?.repeatCourse) return 'repeatCourse';
    if (this.decision?.droppedSchool) return 'droppedSchool';

    return null;
  }

  private _updateValidators(): void {
    const decision = this.decisionForm.get('decision').value;
    const descriptionControl = this.decisionForm.get('description');

    if (decision === 'finishedSchool' || decision === 'droppedSchool' || decision === 'repeatCourse') {
      descriptionControl.removeValidators(NValidate.required);
    } else {
      descriptionControl.setValidators(NValidate.required);
    }
    descriptionControl.updateValueAndValidity({emitEvent: false});
  }
}
