import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {NitFieldWrapperComponent} from '../../../../../../../../core/forms/components/field-wrapper/field-wrapper.component';

@UntilDestroy()
@Component({
  selector: 'nit-decision-textarea',
  templateUrl: './decision-textarea.component.html',
  styleUrl: './decision-textarea.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DecisionTextareaComponent)
    }
  ],
  standalone: true,
  imports: [NitFieldWrapperComponent, FormsModule]
})

export class DecisionTextareaComponent {

  @Input() label: string = '[label]';
  @Input() maxLength: number = 160;
  @Input() rows: number = 3;
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;

  public disabled: boolean = false;
  public touched: boolean = false;
  public value: string = '';

  onChange = (val: any) => {
    this.value = val;
  };
  onTouched = () => { };

  writeValue(val: string) {
    this.value = val;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
