import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'decisionDisabledState',
  standalone: true
})
export class DecisionDisabledStatePipe implements PipeTransform {

  transform(decisionValue: any, decisionType: 'nextClass' | 'finishedSchool' | 'repeatCourse' | 'droppedSchool', leaveTransferId: string): boolean {
    const {decision} = decisionValue;
    if (!decision) return false;

    return decision !== decisionType || !!leaveTransferId;
  }

}
