import {Component, Input, OnChanges, Self, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NValidate, NitForm} from '@nit-core/forms';
import {Decision} from '@nit-core/models/decision';
import {CommonModule} from '@angular/common';
import {NitFieldWrapperComponent} from '@nit-forms';
import {DataPickerComponent} from '@nit-core/components/datepicker/datepicker.component';
import {CustomCheckboxComponent} from '../../../../../../../components/custom-checkbox/custom-checkbox.component';
import {DecisionTextareaComponent} from '../decision-textarea/decision-textarea.component';

@UntilDestroy()
@Component({
  selector: 'nit-leave-form',
  templateUrl: './leave-form.component.html',
  styleUrl: './leave-form.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NitFieldWrapperComponent, DataPickerComponent, CustomCheckboxComponent, DecisionTextareaComponent]
})
export class LeaveFormComponent implements OnChanges, ControlValueAccessor {
  @Input() decision: Decision;
  @Input() showTranseferedTo: boolean = true;
  @Input() isFormShown: boolean = false;
  @Input() repeatCourse: boolean = false;

  leaveForm: NitForm;
  private _value: any;

  get invalid(): boolean {
    return this.leaveForm ? this.leaveForm.invalid : false;
  }

  constructor(@Self() private readonly ngControl: NgControl) {
    ngControl.valueAccessor = this;
    this._initForm();
  }

  onChange = (val: any) => {
    this._value = val;
  };
  onTouched = () => { };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isFormShown || changes.decision ||
      changes.showTranseferedTo && !this.decision.leaveTransferId) {
      this._handleValidations();
    }
  }

  writeValue(val: any): void {
    this.leaveForm.reset({isLeaveAddressUnknown: false}, {emitEvent: false});
    if (val?.leaveDate) val.leaveDate = new Date(val?.leaveDate);
    this.leaveForm.patchValue(val, {emitEvent: false});
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    this.onTouched();
  }

  private _initForm(): void {
    this.leaveForm = new NitForm({
      arbitraryLeaveInfo: new FormControl(),
      isLeaveAddressUnknown: new FormControl(false),
      orderNumber: new FormControl(),
      leaveDate: new FormControl(),
      leaveAddress: new NitForm({
        region: new FormControl(),
        city: new FormControl(),
        school: new FormControl(),
        class: new FormControl(),
      })
    });

    this.leaveForm.valueChanges.subscribe(() => {
      if (this.leaveForm.valid) {
        this.onChange(this.leaveForm.value);
      } else {
        this.onChange(null);
      }
    });

    this.leaveForm.get('isLeaveAddressUnknown').valueChanges.subscribe(() => this._handleValidations());
  }

  private _handleValidations(): void {
    if (!this.repeatCourse) {
      this.leaveForm.get('leaveAddress.region').clearValidators();
      this.leaveForm.get('leaveAddress.city').clearValidators();
      this.leaveForm.get('leaveAddress.school').clearValidators();
      this.leaveForm.get('arbitraryLeaveInfo').clearValidators();
    }

    if (this.isFormShown) {
      this.leaveForm.get('orderNumber').setValidators(NValidate.required);
      this.leaveForm.get('leaveDate').setValidators(NValidate.required);

      if (this.showTranseferedTo) {
        if (this.leaveForm.get('isLeaveAddressUnknown')?.value) {
          this.leaveForm.get('arbitraryLeaveInfo').setValidators(NValidate.required);
        } else {
          this.leaveForm.get('leaveAddress.region').setValidators(NValidate.required);
          this.leaveForm.get('leaveAddress.city').setValidators(NValidate.required);
          this.leaveForm.get('leaveAddress.school').setValidators(NValidate.required);
        }
      }
    }

    if (this.repeatCourse) {
      this.leaveForm.removeControl('leaveAddress');
      this.leaveForm.get('orderNumber').updateValueAndValidity();
      this.leaveForm.get('leaveDate').updateValueAndValidity();
    } else {
      this.leaveForm.get('leaveAddress.region').updateValueAndValidity();
      this.leaveForm.get('leaveAddress.city').updateValueAndValidity();
      this.leaveForm.get('leaveAddress.school').updateValueAndValidity();
      this.leaveForm.get('arbitraryLeaveInfo').updateValueAndValidity();
    }
  }
}
