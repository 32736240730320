<div *ngIf="decisionForm" class="decision">
  <div class="header-decision">Рішення педагогічної ради про</div>
  <form [formGroup]="decisionForm">
    <div class="body-decision">
      <div class="table-wrapper">
        <nit-decision-row label="Переведення до наступного класу"
                          formControlName="decision"
                          value="nextClass"
                          type="radio" 
                          [viewMode]="decisionForm.value | decisionDisabledState : 'nextClass' : decision?.leaveTransferId">
          <nit-decision-textarea label="Назва документа, № та від якого числа"
                                 formControlName="description" />
        </nit-decision-row>
        <nit-decision-row label="Нагородження Похвальним листом"
                          formControlName="commendationLetter"
                          [value]="decisionForm.get('commendationLetter')?.value"
                          [viewMode]="decision?.leaveTransferId && decisionForm.get('decision')?.value === 'repeatCourse'"
                          [isDisabled]="decisionForm.value.decision === 'repeatCourse'" />
        <nit-decision-row label="Видання свідоцтва з відзнакою"
                          formControlName="certificateHonors"
                          [value]="decisionForm.get('certificateHonors')?.value"
                          [viewMode]="decision?.leaveTransferId && decisionForm.get('decision')?.value === 'repeatCourse'"
                          [isDisabled]="decisionForm.value.decision === 'repeatCourse'" />
        <nit-decision-row #finishedSchool label="Випущення з навчального закладу"
                          formControlName="decision"
                          value="finishedSchool"
                          type="radio"
                          [isDisabled]="decisionForm.value | decisionDisabledState : 'finishedSchool' : decision?.leaveTransferId"
                          [viewMode]="!!decision?.leaveTransferId || decisionForm.value.decision === 'nextClass'" [autoHide]="false">
          <nit-leave-form [isFormShown]="finishedSchool.isChecked"
                          [formControl]="leaveFormControl"
                          [decision]="decision" [showTranseferedTo]="false" />
        </nit-decision-row>
        <nit-decision-row #repeatCourse styling="danger"
                          label="Залишення для повторного здобуття загальної середньої освіти"
                          formControlName="decision"
                          value="repeatCourse" type="radio"
                          [isDisabled]="decisionForm.value | decisionDisabledState : 'repeatCourse' : decision?.leaveTransferId"
                          [autoHide]="false"
                          [viewMode]="!!decision?.leaveTransferId || decisionForm.value.decision === 'nextClass'">
          <nit-leave-form [isFormShown]="repeatCourse.isChecked"
                          [formControl]="leaveFormControl"
                          [decision]="decision"
                          [repeatCourse]="true"
                          [showTranseferedTo]="false" />
        </nit-decision-row>
      </div>
    </div>
    <div class="bottom-decision"
         [hidden]="(!!decision?.leaveTransferId && decisionForm.get('decision')?.value !== 'droppedSchool')
           || decisionForm.value.decision === 'nextClass'"
         [class.expanded]="isDroppedSchoolChecked">
      <nit-decision-row #droppedSchool
                        styling="danger"
                        label="Вибуття з навчального закладу"
                        formControlName="decision"
                        value="droppedSchool"
                        type="radio"
                        [autoHide]="false"
                        [viewMode]="!!decision?.leaveTransferId"
                        [isDisabled]="decisionForm.value | decisionDisabledState : 'droppedSchool' : decision?.leaveTransferId"
                        (changeOfChecked)="handleChangeOfChecked($event)">
        <nit-leave-form [isFormShown]="isDroppedSchoolChecked"
                        [formControl]="leaveFormControl"
                        [decision]="decision" />
      </nit-decision-row>
    </div>
    <ng-container *ngIf="!(decision?.leaveTransferId && decisionForm.get('decision')?.value === 'repeatCourse')">
      <nit-button class="submit-btn"
                  [isDisabled]="decisionForm.invalid ||
                    (decisionForm.get('decision')?.value !== 'nextClass' && leaveFormControl.invalid)"
                  (clicked)="onSubmit()">
        Зберегти
      </nit-button>
    </ng-container>
  </form>
</div>
