<form *ngIf="isFormShown" class="leave-form-decision" [formGroup]="leaveForm">
  <div class="inputs-row">
    <nit-field-wrapper [label]="'№ наказу'"
      [required]="!decision?.leaveTransferId">
      <input type="text" formControlName="orderNumber"
        [attr.readonly]="decision?.leaveTransferId">
    </nit-field-wrapper>
    <nit-field-wrapper [label]="'Дата наказу'"
      [required]="!decision?.leaveTransferId">
      <nit-datepicker class="date-picker" formControlName="leaveDate"
        [isDisabled]="decision?.leaveTransferId" [isReadOnly]="decision?.leaveTransferId" />
    </nit-field-wrapper>
  </div>

  <ng-container *ngIf="showTranseferedTo">
    <span class="transefered-to">Куди вибув</span>
    <div class="school-data-present" [hidden]="decision?.leaveTransferId">
      <nit-custom-checkbox formControlName="isLeaveAddressUnknown"
        [isWithBorder]="true"
        [label]="'Дані про навчальний заклад відсутні'" />
    </div>

    <div class="school-data"
      [class.visible]="!leaveForm.get('isLeaveAddressUnknown').value" [formGroup]="leaveForm.get('leaveAddress')">
      <div class="inputs-row">
        <nit-field-wrapper [label]="'Область'"
          [required]="!decision?.leaveTransferId">
          <input type="text" formControlName="region"
            [attr.readonly]="decision?.leaveTransferId">
        </nit-field-wrapper>
        <nit-field-wrapper [label]="'Місто / нас. пункт'"
          [required]="!decision?.leaveTransferId">
          <input type="text" formControlName="city"
            [attr.readonly]="decision?.leaveTransferId">
        </nit-field-wrapper>
      </div>
      <div class="inputs-row">
        <nit-field-wrapper [label]="'Школа'"
          [required]="!decision?.leaveTransferId">
          <input type="text" formControlName="school"
            [attr.readonly]="decision?.leaveTransferId">
        </nit-field-wrapper>
        <nit-field-wrapper [label]="'Клас'">
          <input type="text" formControlName="class"
            [attr.readonly]="decision?.leaveTransferId">
        </nit-field-wrapper>
      </div>
    </div>

    <nit-decision-textarea *ngIf="leaveForm.get('isLeaveAddressUnknown').value"
      label="Довільна інформація куди вибув" formControlName="arbitraryLeaveInfo"
      [maxLength]="90" [readOnly]="decision?.leaveTransferId"
      [required]="!decision?.leaveTransferId" [rows]="5" />
  </ng-container>
</form>
