<div class="nit-decision-textarea">
  <div class="value-length">
    <span>{{value?.length || 0}}/{{maxLength}}</span>
  </div>
  <nit-field-wrapper [label]="label" [required]="required">
    <textarea type="text" class="description" [attr.rows]="rows"
      [attr.maxlength]="maxLength"
      [readOnly]="readOnly" [(ngModel)]="value" (keyup)="onChange(value)"></textarea><br/>
    </nit-field-wrapper>
  </div>
